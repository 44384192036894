import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import * as Icons from "@ant-design/icons-vue";
import store from "@/store/main";
import { ElScrollbar } from "element-plus"
import 'element-plus/es/components/scrollbar/style/css'


const initApp = function (id) {
    const app = createApp(App)


    store.commit('setAppBind', id)

    app.use(Antd);
    app.use(store)
    app.use(ElScrollbar)

    for (const i in Icons) {
        app.component(i, Icons[i]);
    }

    app.mount(id)





}
window.initApp = initApp
