<template>
    <div class="system-body-main">
        <div class="system-list">
            <div class="system-list-header">
                <a-input-search
                    v-model:value="keywords"
                    placeholder="搜索"
                    size="small"
                    style=""
                    @search="onSearch"
                />
            </div>
            <div class="system-list-body">
                <el-scrollbar>
                    <div class="list-avatar-title">好友申请</div>
                    <div class="ul-avatar-list" style="">
                        <div
                            class="ul-avatar-list-item"
                            :class="{
                                current:
                                    currentType == 'user' &&
                                    currentItem != null &&
                                    currentItem.id == item.target.id,
                            }"
                            v-for="(item, index) in listApplyFriendSort"
                            :key="item.id"
                            @click="
                                onSelectItem(item, 'apply-' + index, 'user')
                            "
                        >
                            <div
                                class="ul-avatar-list-item-img"
                                :style="{
                                    backgroundImage:
                                        'url(' + item.target.avatar + ')',
                                }"
                            ></div>
                            <div class="ul-avatar-list-item-info">
                                <div class="ul-avatar-list-item-title">
                                    {{ item.target.nickname }}
                                </div>

                                <div class="ul-avatar-list-item-time"></div>
                            </div>
                        </div>
                    </div>
                    <div class="list-avatar-title">群聊</div>
                    <div class="ul-avatar-list" style="">
                        <div
                            class="ul-avatar-list-item"
                            :class="{
                                current:
                                    currentType == 'group' &&
                                    currentItem != null &&
                                    currentItem.id == item.group_id,
                            }"
                            v-for="(item, index) in listMyGroupSort"
                            :key="'group-' + item.id"
                            @click="onSelectItem(item, index, 'group')"
                        >
                            <div
                                class="ul-avatar-list-item-img"
                                :style="{
                                    backgroundImage:
                                        'url(' + item.group.title_img + ')',
                                }"
                            ></div>
                            <div class="ul-avatar-list-item-info">
                                <div class="ul-avatar-list-item-title">
                                    {{ item.group.title }} （{{
                                        item.group.chat_group_user_count
                                    }}）
                                </div>

                                <div class="ul-avatar-list-item-time"></div>
                            </div>
                        </div>
                    </div>
                    <div class="list-avatar-title">好友</div>
                    <div class="ul-avatar-list" style="">
                        <div
                            class="ul-avatar-list-item"
                            :class="{
                                current:
                                    currentType == 'user' &&
                                    currentItem != null &&
                                    currentItem.id == item.target_user_id,
                            }"
                            v-for="(item, index) in listMyFriendSort"
                            :key="item.id"
                            @click="onSelectItem(item, index, 'user')"
                        >
                            <div
                                class="ul-avatar-list-item-img"
                                :style="{
                                    backgroundImage:
                                        'url(' + item.target.avatar + ')',
                                }"
                            ></div>
                            <div class="ul-avatar-list-item-info">
                                <div class="ul-avatar-list-item-title">
                                    {{ item.target.nickname }}
                                </div>

                                <div class="ul-avatar-list-item-time"></div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="system-panel">
            <div class="system-panel-main">
                <InfoCardComponent
                    v-if="currentItem != null"
                ></InfoCardComponent>
            </div>
        </div>
    </div>
</template>

<script>
import InfoCardComponent from "@/components/InfoCardComponent";
import store from "@/store/main";
import { mapState } from "vuex";
export default {
    components: {
        InfoCardComponent,
    },
    data() {
        return {
            keywords: "",
        };
    },
    computed: {
        ...mapState(["listMyFriend", "listMyGroup", "listApplyFriend"]),
        ...mapState({
            currentItem: "currentInfoCardItem",
            currentType: "currentInfoCardType",
        }),
        listMyFriendSort: function () {
            return this.listMyFriend.filter(
                (item) =>
                    this.keywords.length == 0 ||
                    item.target.nickname.indexOf(this.keywords) > -1
            );
        },
        listApplyFriendSort: function () {
            return this.listApplyFriend.filter(
                (item) =>
                    this.keywords.length == 0 ||
                    item.target.nickname.indexOf(this.keywords) > -1
            );
        },
        listMyGroupSort: function () {
            return this.listMyGroup.filter(
                (item) =>
                    this.keywords.length == 0 ||
                    item.group.title.indexOf(this.keywords) > -1
            );
        },
    },
    methods: {
        onSearch() {},
        onSelectItem(item, index, type) {
            var currentItem;
            if (type == "group") {
                currentItem = item.group;
            } else {
                currentItem = item.target;
            }

            store.commit("setCurrentInfoCardItem", {
                currentItem,
                type: type,
            });
        },
    },
};
</script>

<style scoped>
</style>