<template>
    <div>
        <LoginComponent v-if="userinfo == null"></LoginComponent>
        <MainComponent v-else></MainComponent>
    </div>
</template>

<script>
import { mapState } from "vuex";
import MainComponent from "@/components/MainComponent";
import LoginComponent from "@/components/LoginComponent";
import tools from '@/assets/js/tools';
import store from '@/store/main';

export default {
    data() {
        return {
            currentNavbar: "message",
        };
    },
    computed: {
        ...mapState(["userinfo"]),
    },
    components: {
        MainComponent,
        LoginComponent,
    },
    created() {

        tools.request('Index/info',(result)=>{
            store.state.sysconfig = result.data;

            document.title = result.data.site_name;
            var faviconurl = result.data.site_favicon;
            var link =
                document.querySelector("link[rel*='icon']") ||
                document.createElement("link");

            link.type = "image/x-icon";

            link.rel = "shortcut icon";

            link.href = faviconurl;

            document.getElementsByTagName("head")[0].appendChild(link);
        })

    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.into-container {
    position: fixed;
    right: 80px;
    bottom: 80px;
    z-index: 999999;
    height: 600px;
    width: 800px;
    background-color: #bbb;
    display: flex;
    box-shadow: 0 0 1px #bbb;
}

.system-navbar {
    height: 100%;
    width: 60px;
    background-color: rgba(46, 46, 46);
}
.system-body {
    width: calc(100% - 60px);
}

.system-navbar-group {
    margin-bottom: 20px;
}
.system-navbar-item {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}
.system-navbar-item.current {
    color: rgb(7, 193, 96);
}
.system-navbar-main {
    padding: 10px;
}
</style>