<template>
    <div>
        <a-config-provider :locale="locale">
            <a-button
                type="primary"
                shape="circle"
                size="large"
                @click="onMainDisplay"
            >
                <template #icon>
                    <MessageOutlined />
                </template>
            </a-button>
            <IntoComponent
                :style="{
                    display: mainDisplay,
                }"
            ></IntoComponent>
        </a-config-provider>
    </div>
</template>

<script>
import IntoComponent from "@/components/IntoComponent";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default {
    name: "App",
    components: {
        IntoComponent,
    },
    data() {
        return {
            locale: zhCN,
            mainDisplay: "block",
        };
    },
    created() {},
    methods: {
        onMainDisplay() {
            if (this.mainDisplay == "block") {
                this.mainDisplay = "none";
            } else {
                this.mainDisplay = "block";
            }
        },
    },
};
</script>

<style scoped>
@import "@/assets/css/common.css";
</style>
