<template>
    <div class="info-card" v-if="item != null && !closeItem">
        <a-descriptions
            title="基本信息"
            :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }"
        >
            <a-descriptions-item label="头像">
                <a-avatar
                    v-if="
                        type == 'user' || chatInfo == null || chatInfo.type != 2
                    "
                    :size="64"
                    :src="item.title_img"
                >
                    <template #icon><UserOutlined /></template>
                </a-avatar>
                <a-spin v-else :spinning="uploadTitleImg">
                    <a-upload
                        accept="image/*"
                        :showUploadList="false"
                        :beforeUpload="beforeUpload"
                    >
                        <a-avatar
                            :size="64"
                            :src="item.title_img"
                            style="cursor: pointer"
                        >
                            <template #icon><UserOutlined /></template>
                        </a-avatar>
                    </a-upload>
                </a-spin>
            </a-descriptions-item>
            <a-descriptions-item label="UID">
                {{ item.uid }}
            </a-descriptions-item>
            <a-descriptions-item label="名称">
                {{ item.title }}
                <span
                    class="edit-option"
                    v-if="
                        type == 'group' &&
                        chatInfo != null &&
                        chatInfo.type == 2
                    "
                    @click="showEditGroupName = true"
                >
                    <form-outlined />
                </span>
            </a-descriptions-item>
            <a-descriptions-item label="群介绍" v-if="type == 'group'">
                {{ item.title_desc }}
                <span
                    class="edit-option"
                    v-if="
                        type == 'group' &&
                        chatInfo != null &&
                        chatInfo.type == 2
                    "
                    @click="showEditGroupDesc = true"
                >
                    <form-outlined />
                </span>
            </a-descriptions-item>
            <a-descriptions-item label="账号" v-if="type == 'user'">
                {{ item.account }}
            </a-descriptions-item>
        </a-descriptions>

        <a-spin :spinning="loadingChatInfo">
            <div class="info-options" v-if="type == 'user'">
                <a-button
                    type="primary"
                    block
                    v-if="
                        chatInfo == null ||
                        chatInfo.status == 1 ||
                        chatInfo.status == 3
                    "
                    @click="makeFriend"
                    >加好友</a-button
                >
                <a-button
                    block
                    v-if="
                        chatInfo == null ||
                        chatInfo.status == 0 ||
                        chatInfo.status == 1 ||
                        chatInfo.status == 3
                    "
                    @click="makeChat"
                    >发消息</a-button
                >
                <a-button
                    block
                    v-if="chatInfo != null && chatInfo.status == 4"
                    @click="confirmFriend"
                    >同意申请</a-button
                >

                <a-button
                    block
                    v-if="chatInfo != null && chatInfo.status == 0"
                    danger
                    type="primary"
                    @click="deleteFriend"
                    >删除好友</a-button
                >
            </div>
            <div class="info-options" v-if="type == 'group'">
                <a-button
                    type="primary"
                    block
                    v-if="chatInfo == null"
                    @click="applyGroup"
                    >申请加群</a-button
                >
                <a-button block @click="makeGroupChat" v-if="chatInfo != null"
                    >发消息</a-button
                >

                <a-button
                    block
                    v-if="chatInfo != null"
                    danger
                    type="primary"
                    @click="leaveGroup"
                    >退群</a-button
                >
                <a-button
                    block
                    v-if="chatInfo != null && chatInfo.type == 2"
                    danger
                    type="primary"
                    @click="closeGroup"
                    >解散群聊</a-button
                >
            </div>

            <div v-if="type == 'group' && chatInfo != null">
                <div class="list-avatar-title">群员列表</div>
                <div class="list-user-group">
                    <div
                        class="item"
                        v-for="item in listGroupUser"
                        :key="'chat-group-user' + item.id"
                    >
                        <a-popover :title="item.user.nickname" trigger="click">
                            <template #content>
                                <div class="info-item">
                                    <span class="name">UID</span>
                                    <span class="value">
                                        {{ item.user.uid }}
                                    </span>
                                </div>
                                <div class="info-item">
                                    <span class="name">账号</span>
                                    <span class="value">
                                        {{ item.user.account }}
                                    </span>
                                </div>
                                <div class="info-item">
                                    <span class="name">角色</span>
                                    <span class="value">
                                        {{ item.type_name }}
                                    </span>
                                </div>
                                <div class="" style="text-align: right">
                                    <a-button
                                        type="primary"
                                        danger
                                        size="small"
                                        @click="closeGroup(item)"
                                        v-if="
                                            item.type != 2 && chatInfo.type == 2
                                        "
                                        >移除群聊</a-button
                                    >
                                </div>
                                <!-- TODO:在这里发起临时聊天、加好友等 -->
                            </template>

                            <a-avatar shape="square" :src="item.user.avatar">
                            </a-avatar>
                        </a-popover>
                    </div>
                    <div class="item">
                        <a-avatar shape="square">
                            <template #icon>
                                <plus-outlined
                                    @click="showFriendSelect = true"
                                />
                            </template>
                        </a-avatar>
                    </div>
                </div>
            </div>
        </a-spin>

        <a-modal
            v-model:visible="showApplyGroupPassword"
            title="请输入密码"
            ok-text="确认"
            cancel-text="取消"
            @ok="onApplyGroupRequest"
        >
            <a-input
                v-model:value="applyGroupPassword"
                placeholder="请输入加群密码"
            />
        </a-modal>
        <a-modal
            v-model:visible="showEditGroupName"
            title="请输入名称"
            ok-text="确认"
            cancel-text="取消"
            @ok="onConfirmUpdateGroup"
        >
            <a-input v-model:value="groupItem.title" placeholder="请输入名称" />
        </a-modal>
        <a-modal
            v-model:visible="showEditGroupDesc"
            title="请输入描述"
            ok-text="确认"
            cancel-text="取消"
            @ok="onConfirmUpdateGroup"
        >
            <a-textarea
                v-model:value="groupItem.title_desc"
                placeholder="请输入描述"
            />
        </a-modal>
        <FriendSelectComponent
            v-model:show="showFriendSelect"
            :listDisableId="listDisableId"
            @onConfirm="onConfirmSelect"
        ></FriendSelectComponent>
    </div>
</template>

<script>
import tools from "@/assets/js/tools";
import { Modal, notification } from "ant-design-vue";
import store from "@/store/main";
import FriendSelectComponent from "@/components/FriendSelectComponent";
import { mapState } from "vuex";
export default {
    components: {
        FriendSelectComponent,
    },

    data() {
        return {
            loadingChatInfo: false,
            chatInfo: null,
            showApplyGroupPassword: false,
            applyGroupPassword: "",
            listGroupUser: [],
            showFriendSelect: false,
            closeItem: false,
            showEditGroupName: false,
            showEditGroupDesc: false,
            groupItem: {},
            fileList: [],
            baseHost: tools.baseHost,
            uploadTitleImg: false,
        };
    },
    computed: {
        listDisableId() {
            return this.listGroupUser.map((item) => item.user_id);
        },
        ...mapState({
            item: "currentInfoCardItem",
            type: "currentInfoCardType",
        }),
    },

    created() {
        this.loadChatInfo(this.item);
    },
    watch: {
        item(newV, oldV) {
            this.loadChatInfo(newV);
        },
    },
    methods: {
        loadChatInfo(item) {
            this.groupItem = item;
            this.closeItem = false;
            if (this.type == "group") {
                this.loadingChatInfo = true;
                tools.request(
                    "ChatUser/getChatGroupInfo",
                    {
                        group_id: item.id,
                    },
                    (result) => {
                        this.loadingChatInfo = false;
                        this.chatInfo = result.data;

                        if (this.chatInfo != null) {
                            tools.request(
                                "/ChatUser/listGroupUser",
                                {
                                    group_id: this.item.id,
                                },
                                (resultListUser) => {
                                    this.listGroupUser = resultListUser.data;
                                }
                            );
                        }
                    }
                );

                return false;
            }

            this.loadingChatInfo = true;

            tools.request(
                "ChatUser/getChatInfo",
                {
                    target_user_id: item.id,
                },
                (result) => {
                    this.loadingChatInfo = false;
                    this.chatInfo = result.data;
                }
            );
        },

        makeFriend() {
            this.loadingChatInfo = true;

            tools.request(
                "/ChatUser/makeFriend",
                {
                    target_user_id: this.item.id,
                },
                (result, code) => {
                    if (code == 500) {
                        notification.info({
                            message: result.msg,
                        });
                    }

                    this.loadingChatInfo = false;
                    this.loadChatInfo(this.item);
                    store.dispatch("setListFriend");
                    store.dispatch("setListChat");
                },
                {
                    closeNormalErrorTips: true,
                }
            );
        },
        deleteFriend() {
            Modal.confirm({
                title: "确定要删除吗?",
                mask: true,
                onOk: () => {
                    this.loadingChatInfo = true;

                    tools.request(
                        "/ChatUser/deleteFriend",
                        {
                            target_user_id: this.item.id,
                        },
                        (result) => {
                            notification.success({
                                message: "删除成功",
                            });
                            this.loadingChatInfo = false;
                            this.loadChatInfo(this.item);
                            store.dispatch("setListFriend");
                            store.dispatch("setListChat");
                        }
                    );
                },
            });
        },
        confirmFriend() {
            this.loadingChatInfo = true;
            tools.request(
                "/ChatUser/confirmFriend",
                {
                    target_user_id: this.item.id,
                },
                (result) => {
                    this.loadingChatInfo = false;
                    this.loadChatInfo(this.item);
                    store.dispatch("setListFriend");
                    store.dispatch("setListChat");
                }
            );
        },

        makeChat() {
            this.loadingChatInfo = true;

            tools.request(
                "/ChatUser/makeChat",
                {
                    target_user_id: this.item.id,
                },
                (result, code) => {
                    this.loadingChatInfo = false;
                    if (code != 0) {
                        return false;
                    }

                    store.dispatch("setCurrentChatByUid", result.data.uid);
                    store.dispatch("setListFriend");
                    store.dispatch("setListChat");
                }
            );
        },
        applyGroup() {
            if (this.item.password.length == 0) {
                this.onApplyGroupRequest();
            } else {
                this.showApplyGroupPassword = true;
            }
        },
        onApplyGroupRequest() {
            this.loadingChatInfo = true;
            tools.request(
                "/ChatUser/applyGroup",
                {
                    group_id: this.item.id,
                    password: this.applyGroupPassword,
                },
                (result, code) => {
                    this.loadingChatInfo = false;
                    if (code != 0) {
                        return false;
                    }
                    this.showApplyGroupPassword = false;
                    this.loadChatInfo(this.item);
                    store.dispatch("setListFriend");
                    store.dispatch("setListChat");
                }
            );
        },
        makeGroupChat() {
            store.dispatch("setCurrentChatByUid", this.item.room_uid);
        },

        leaveGroup() {
            Modal.confirm({
                title: "确定要退出吗?",
                mask: true,
                onOk: () => {
                    this.loadingChatInfo = true;

                    tools.request(
                        "/ChatUser/leaveGroup",
                        {
                            group_id: this.item.id,
                        },
                        (result, code) => {
                            this.loadingChatInfo = false;
                            if (code != 0) {
                                return false;
                            }
                            this.loadChatInfo(this.item);
                            store.dispatch("setListFriend");
                            store.dispatch("setListChat");
                        }
                    );
                },
            });
        },

        removeGroup(groupUser) {
            Modal.confirm({
                title: "确定要将该用户移除聊天吗?",
                mask: true,
                zIndex: 1050,
                onOk: () => {
                    this.loadingChatInfo = true;

                    tools.request(
                        "/ChatUser/leaveGroup",
                        {
                            group_id: this.item.id,
                            user_id: groupUser.user_id,
                        },
                        (result, code) => {
                            this.loadingChatInfo = false;
                            if (code != 0) {
                                return false;
                            }
                            this.loadChatInfo(this.item);
                            store.dispatch("setListFriend");
                            store.dispatch("setListChat");
                        }
                    );
                },
            });
        },

        closeGroup() {
            Modal.confirm({
                title: "确定要解散群聊吗?",
                mask: true,
                zIndex: 1050,
                onOk: () => {
                    this.loadingChatInfo = true;

                    tools.request(
                        "/ChatUser/closeGroup",
                        {
                            group_id: this.item.id,
                        },
                        (result, code) => {
                            this.loadingChatInfo = false;
                            if (code != 0) {
                                return false;
                            }
                            this.closeItem = true;
                            store.dispatch("setListFriend");
                            store.dispatch("setListChat");
                        }
                    );
                },
            });
        },

        onConfirmSelect(list) {
            var listUserId = list.map((item) => item.target_user_id);

            tools.request(
                "/ChatUser/addGroupUser",
                {
                    group_id: this.item.id,
                    list_user_id: listUserId,
                },
                (result) => {
                    store.dispatch("setListFriend");
                    store.dispatch("setListChat");
                    this.loadChatInfo(this.item);
                }
            );
        },
        onConfirmUpdateGroup() {
            tools.request(
                "/ChatUser/updateGroup",
                {
                    group_id: this.item.id,
                    data: this.groupItem,
                },
                (result) => {
                    var item = this.groupItem;

                    this.$emit("update:item", item);
                    this.showEditGroupName = false;
                    this.showEditGroupDesc = false;
                    store.dispatch("setListFriend");
                    store.dispatch("setListChat");
                    this.loadChatInfo(this.item);
                }
            );
        },

        beforeUpload(file) {
            this.handleUpload(file);
            return false;
        },
        handleUpload(file) {
            this.uploadTitleImg = true;
            tools.uploadFile(file, "chat", (result) => {
                this.uploadTitleImg = false;
                this.groupItem.title_img = result.data.src;

                this.onConfirmUpdateGroup();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.info-card {
    margin: 30px;
}
.edit-option {
    color: #999;
    cursor: pointer;
}

.info-options {
    padding: 30px;
}
.info-options > * {
    margin-bottom: 10px;
}

.list-user-group {
    margin: 10px;

    .item {
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        display: inline-block;
    }
}
</style>