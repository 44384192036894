import tools from "@/assets/js/tools";
import { createStore } from "vuex";

const store = createStore({
    state() {
        return {
            appBind: '',
            searchKeywords: '',
            userinfo: null,
            session: null,
            currentNavbar: 'message',
            currentInfoCardItem: null,
            currentInfoCardType: 'user',
            listChat: [],
            currentChat: null,
            listApplyFriend: [],
            listMyFriend: [],
            listMyGroup: [],
            sysconfig: {}
        }
    },
    getters: {
        getListChatItem(state) {
            return (roomUid) => {
                return state.listChat.find(item => item.room_uid == roomUid)
            }
        }
    },
    mutations: {
        setUserinfo(state, userinfo) {
            state.userinfo = userinfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        setAppBind(state, id) {
            state.appBind = id;
        },
        setListChat(state, list) {
            state.listChat = list.sort(function (b, a) {
                var x = a.original_update_time;
                var y = b.original_update_time;
                if (x < y) {
                    return -1;
                } else if (x > y) {
                    return 1;
                } else {
                    return 0;
                }
            })
        },
        setListChatItem(state, item) {
            var list = state.listChat;

            var index = list.findIndex(function (value, index) {
                return value.id == item.id
            });
            list[index] = item;
            this.commit('setListChat', list);
        },
        setCurrentChat(state, chat) {
            state.currentChat = chat;
        },
        setCurrentNavbar(state, type) {
            state.currentNavbar = type;
        },
        setCurrentInfoCardItem(state, data) {
            if (data == null) {
                state.currentInfoCardItem = null;
                state.currentInfoCardType = null;
                return true;
            }
            if (data.type == "user") {
                data.currentItem.title = data.currentItem.nickname;
                data.currentItem.uid = data.currentItem.account;
                data.currentItem.title_img = data.currentItem.avatar;
            }

            state.currentInfoCardItem = data.currentItem;
            state.currentInfoCardType = data.type;
        },
        setListApplyFriend(state, list) {
            state.listApplyFriend = list;
        },
        setListMyFriend(state, list) {
            state.listMyFriend = list;
        },
        setListMyGroup(state, list) {
            state.listMyGroup = list
        }

    },
    actions: {
        setListChat({ commit }) {
            tools.request('/Chat/listChat', (result) => {
                commit('setListChat', result.data)
            })
        },
        setListFriend({ commit }) {
            tools.request('/ChatUser/listUser', (result) => {
                commit('setListMyFriend', result.data)
            })
            tools.request('/ChatUser/listGroup', (result) => {
                commit('setListMyGroup', result.data)
            })
            tools.request('/ChatUser/applyUserIndex', (result) => {
                commit('setListApplyFriend', result.data)
            })
        },
        setCurrentChatByUid({ commit }, uid, isJumpToChat) {

            if (typeof isJumpToChat == 'undefined') {
                isJumpToChat = true;
            }

            tools.request("/Chat/readChat", {
                uid: uid
            }, (result) => {
                commit('setCurrentChat', result.data.model_room_user)

                if (isJumpToChat) {
                    commit('setCurrentNavbar', 'message')
                }
            })
        }
    }

})

export default store;