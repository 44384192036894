<template>
    <div class="login-container">
        <a-spin :spinning="isContainerLoading">
            <a-form layout="vertical" :model="formState">
                <a-form-item label="昵称">
                    <a-input
                        v-model:value="formState.nickname"
                        placeholder="请输入昵称"
                    />
                </a-form-item>
                <a-form-item>
                    <a-spin :spinning="loading">
                        <a-button type="primary" block @click="onLogin"
                            >快速登陆</a-button
                        >
                    </a-spin>
                </a-form-item>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
import tools from "@/assets/js/tools";
import store from "@/store/main";
export default {
    data() {
        return {
            loading: false,
            isContainerLoading: true,
            formState: {
                nickname: "anychat-" + Math.round(Math.random() * 100000000),
            },
        };
    },
    created() {
        var session = tools.storage.get("session");
        store.commit("setSession", session);

        tools.request(
            "Index/myinfo",
            (result, code) => {
                this.isContainerLoading = false;
                if (code == 700) {
                    store.commit("setUserinfo", null);
                    return false;
                }
                store.commit("setUserinfo", result.data);
            },
            {
                closeLoginStop: true,
            }
        );
    },
    methods: {
        onLogin() {
            this.loading = true;
            tools.request("Index/login", this.formState, (result) => {
                this.loading = false;

                store.commit("setUserinfo", result.data.user);
                store.commit("setSession", result.data.session_info);

                tools.storage.set("session", result.data.session_info);
            });
        },
    },
};
</script>

<style scoped>
.login-container {
    position: fixed;
    right: 80px;
    bottom: 80px;
    z-index: 999999;
    background-color: #fff;
    display: flex;
    box-shadow: 0 0 1px #bbb;
    text-align: center;
    padding: 25px;
}
</style>