<template>
    <div class="move-body">
        <div
            class="move-container"
            :style="{ left: moveDefaultX + 'px', top: moveDefaultY + 'px' }"
        >
            <div class="into-container">
                <div class="system-navbar">
                    <div class="system-navbar-main">
                        <div class="system-navbar-group">
                            <div
                                class="system-navbar-item"
                                @click="changeNav('mine')"
                            >
                                <a-image
                                    :width="40"
                                    :preview="false"
                                    :src="userinfo.avatar"
                                    class="disable-events"
                                />
                            </div>
                        </div>
                        <div class="system-navbar-group">
                            <div
                                class="system-navbar-item"
                                :class="{ current: currentNavbar == 'message' }"
                                @click="changeNav('message')"
                            >
                                <MessageOutlined />
                            </div>
                            <div
                                class="system-navbar-item"
                                :class="{ current: currentNavbar == 'team' }"
                                @click="changeNav('team')"
                            >
                                <TeamOutlined />
                            </div>
                            <div
                                class="system-navbar-item"
                                :class="{ current: currentNavbar == 'search' }"
                                @click="changeNav('search')"
                            >
                                <SearchOutlined />
                            </div>
                        </div>
                        <div class="drag-option drag-option-button">
                            <drag-outlined />
                        </div>
                    </div>
                </div>
                <div class="system-body">
                    <MessageComponent v-if="currentNavbar == 'message'" />
                    <FriendComponent v-if="currentNavbar == 'team'" />
                    <SearchComponent v-if="currentNavbar == 'search'" />
                    <MineComponent v-if="currentNavbar == 'mine'" />
                </div>
            </div>
        </div>
        <div style="display: none">
            <audio
                ref="massgeAudio"
                id="message-audio"
                :src="messageAudioSrc"
            />
        </div>
    </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";
import FriendComponent from "@/components/FriendComponent";
import SearchComponent from "@/components/SearchComponent";
import MineComponent from "@/components/MineComponent";
import { mapState } from "vuex";
import tools from "@/assets/js/tools";
import store from "@/store/main";

import messageAudioSrc from "@/assets/audio/default.mp3";
import { notification, Button } from "ant-design-vue";
import { h } from "vue";

export default {
    data() {
        return {
            socket: null,
            socketTimer: null,
            messageAudioSrc: messageAudioSrc,
            moveDefaultX: window.innerWidth - 800 - 100,
            moveDefaultY: window.innerHeight - 600 - 100,
        };
    },
    moveLock: false,
    moveStartX: 0,
    moveStartY: 0,
    moveOriginalX: 0,
    moveOriginalY: 0,

    computed: {
        ...mapState(["userinfo", "currentNavbar", "currentChat"]),
    },
    components: {
        MessageComponent,
        FriendComponent,
        SearchComponent,
        MineComponent,
    },
    created() {
        var lastOpenNav = tools.storage.get("last-open-nav");

        if (lastOpenNav) {
            store.commit("setCurrentNavbar", lastOpenNav);
        }
        store.dispatch("setListChat");
        store.dispatch("setListFriend");

        this.socket = new WebSocket(tools.wssHost);
        this.socket.onopen = () => {
            console.log("connected");
            this.socketTimer = setInterval(() => {
                this.wssSendMessage({
                    type: "ping",
                });
            }, 10000);

            this.wssSendMessage({
                type: "login",
            });
        };

        this.socket.onmessage = (revice) => {
            var result = JSON.parse(revice.data);
            var type = result.data.type;
            var data = result.data;
            if (type == "new_tips") {
                if (data.data.user_id != this.userinfo.id) {
                    this.$refs.massgeAudio.play();

                    if (
                        this.currentChat == null ||
                        this.currentChat.room_uid != data.data.room_uid
                    ) {
                        var reviceChat = store.getters.getListChatItem(
                            data.data.room_uid
                        );
                        if (reviceChat) {
                            reviceChat.uncheck_message_count++;
                            store.commit("setListChatItem", reviceChat);
                        }
                    }
                }
                tools.message.emit(data);
            } else if (type == "new_apply") {
                store.dispatch("setListFriend");
                this.$refs.massgeAudio.play();
                var key = `open${Date.now()}`;
                // 收到一条好友申请
                notification.info({
                    message: "收到一条好友申请",
                    btn: h(
                        Button,
                        {
                            type: "primary",
                            site: "small",
                            onclick: () => {
                                notification.close(key);
                                this.changeNav("team");
                            },
                        },
                        "查看"
                    ),
                    key,
                });
            } else if (type == "new_confirm") {
                this.$refs.massgeAudio.play();
                // 您的好友申请已通过
                store.dispatch("setListFriend");
                store.dispatch("setListChat");
            } else if (type == "update_chat_list") {
                store.dispatch("setListFriend");
                store.dispatch("setListChat");
            }
        };

        document.onmousedown = this.onDragStart;

        document.onmousemove = this.onDragMove;

        document.onmouseup = this.onDragEnd;
    },
    methods: {
        onDragStart(e) {
            if (!e.target) {
                return true;
            }
            if (!e.target.className) {
                return true;
            }

            if (typeof e.target.className != "string") {
                return true;
            }

            var classNameList = e.target.className.split(" ");

            var dragElemClassNameList = [
                "drag-option",
                "system-navbar",
                "system-navbar-group",
                "system-list-header",
                "system-list-body",
                "system-panel-header",
                "system-panel",
                "system-panel-main",
            ];

            var hasDragClass = false;
            for (let index = 0; index < dragElemClassNameList.length; index++) {
                const className = dragElemClassNameList[index];

                if (classNameList.indexOf(className) >= 0) {
                    hasDragClass = true;
                    break;
                }
            }

            if (!hasDragClass) {
                return true;
            }
            this.moveLock = true;
            this.moveStartX = e.clientX;
            this.moveStartY = e.clientY;

            this.moveOriginalX = this.moveDefaultX;
            this.moveOriginalY = this.moveDefaultY;
        },
        onDragMove(e) {
            if (!this.moveLock) {
                return true;
            }

            this.moveDefaultX =
                this.moveOriginalX + (-this.moveStartX + e.clientX);
            this.moveDefaultY =
                this.moveOriginalY + (-this.moveStartY + e.clientY);
        },
        onDragEnd(e) {
            this.moveLock = false;
        },
        wssSendMessage(item) {
            if (this.socket == null) {
                console.error("send field ,socket not open");
                return false;
            }

            if (this.socket.readyState == 1) {
                item.user_id = this.userinfo.id;

                this.socket.send(JSON.stringify(item));
            } else {
                console.error("send field ,socket error");
            }
        },
        changeNav(item) {
            tools.storage.set("last-open-nav", item);
            store.commit("setCurrentNavbar", item);
        },
    },
};
</script>

<style lang="scss" scoped>
.drag-option-button {
    color: #fff;
    text-align: center;
    cursor: move;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #444;
}
.drag-option-button * {
    pointer-events: none;
}
.move-body {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999;
    pointer-events: none;
    left: 0;
    top: 0;
}
.move-container {
    pointer-events: all;
    width: 800px;
    height: 600px;
    position: absolute;
    right: 65px;
    bottom: 65px;
}
.into-container {
    background-color: #bbb;
    display: flex;
    box-shadow: 0 0 1px #bbb;
    width: 100%;
    height: 100%;
    position: relative;
}

.system-navbar {
    height: 100%;
    width: 60px;
    background-color: rgba(46, 46, 46);
    position: relative;
}
.system-body {
    width: calc(100% - 60px);
}

.system-navbar-group {
    margin-bottom: 20px;
}
.system-navbar-item {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}
.system-navbar-item.current {
    color: rgb(7, 193, 96);
}
.system-navbar-main {
    margin: 10px;
}
</style>