<template>
    <div class="system-panel-main" v-if="currentChat != null">
        <div class="system-panel-header">
            <div class="system-panel-title">
                {{ currentChat.room.target_info.title }}
                <span v-if="currentChat != null && currentChat.room.type == 1">
                    （{{ currentChat.room.target_info.chat_group_user_count }}）
                </span>
            </div>
            <div class="system-panel-option" @click="goInfoCard">
                <ellipsis-outlined />
            </div>
        </div>
        <div class="system-panel-body">
            <div class="chat-container">
                <div class="chat-history">
                    <el-scrollbar
                        ref="chatHistoryScroll"
                        @scroll="onChatHistoryScroll"
                    >
                        <div ref="chatHistoryScrollInner">
                            <div class="system-tips" v-if="isHistoryLoadAll">
                                数据加载完毕
                            </div>
                            <div class="system-tips" v-else>上滑加载更多</div>

                            <div
                                class="chat-line"
                                :class="{
                                    'chat-chat-mine':
                                        userinfo.id == itemHistory.user_id,
                                }"
                                v-for="itemHistory in listChatHistory"
                                v-bind:key="'chat-line-' + itemHistory.id"
                            >
                                <div
                                    class="chat-line-text"
                                    v-if="itemHistory.type != 4"
                                >
                                    <div
                                        class="chat-chat-user"
                                        v-if="itemHistory.user_id > 0"
                                    >
                                        <img
                                            class="avatar"
                                            :src="itemHistory.showAvatar"
                                        />
                                        <cite class="nickname">
                                            {{ itemHistory.showNickname }}
                                        </cite>
                                    </div>
                                    <div class="chat-chat-main">
                                        <div
                                            class="
                                                chat-chat-text chat-chat-record
                                            "
                                            v-if="itemHistory.type == 0"
                                            v-html="itemHistory.showContent"
                                        ></div>

                                        <div
                                            v-if="itemHistory.type == 1"
                                            class="
                                                chat-chat-text chat-chat-record
                                            "
                                        >
                                            <a-image
                                                :src="itemHistory.showContent"
                                            />
                                        </div>

                                        <ChatRecordFileComponent
                                            class="chat-chat-record"
                                            v-if="itemHistory.type == 3"
                                            :item="itemHistory"
                                        ></ChatRecordFileComponent>
                                    </div>
                                </div>
                                <div
                                    class="chat-chat-tips"
                                    v-if="itemHistory.type == 4"
                                >
                                    <div
                                        class="main"
                                        v-html="itemHistory.showContent"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="chat-input">
                    <div class="chat-input-option">
                        <div class="chat-input-option-item">
                            <SmileOutlined @click="onToggleFace" />
                            <div class="chat-option-panel" v-if="showFace">
                                <div
                                    class="chat-option-panel-model"
                                    @click="showFace = false"
                                ></div>
                                <div class="chat-face-container">
                                    <img
                                        :src="baseHost + itemFace"
                                        :key="indexFace"
                                        class="face-item"
                                        @click="
                                            onFaceSelect(itemFace, indexFace)
                                        "
                                        v-for="(
                                            itemFace, indexFace
                                        ) in listFace"
                                    />
                                </div>
                            </div>
                        </div>
                        <a-upload
                            accept="image/*"
                            :showUploadList="false"
                            :beforeUpload="beforeUpload"
                            :multiple="true"
                        >
                            <div class="chat-input-option-item">
                                <PictureOutlined />
                            </div>
                        </a-upload>
                        <a-upload
                            :showUploadList="false"
                            :beforeUpload="beforeUpload"
                            :multiple="true"
                        >
                            <div class="chat-input-option-item">
                                <UploadOutlined />
                            </div>
                        </a-upload>
                    </div>
                    <div class="chat-input-textarea">
                        <textarea v-model="inputContent"></textarea>
                    </div>
                    <div class="chat-input-button">
                        <a-spin :spinning="isPosting">
                            <a-button
                                :disabled="inputContent.length == 0"
                                size="small"
                                @click="postChat"
                                >发送</a-button
                            >
                        </a-spin>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tools from "@/assets/js/tools";
import face from "@/assets/js/face.js";
import { mapState } from "vuex";
import store from "@/store/main";
import ChatRecordFileComponent from "@/components/ChatRecordFileComponent";
export default {
    components: {
        ChatRecordFileComponent,
    },
    data() {
        return {
            isPosting: false,
            inputContent: "",
            listChatHistory: [],
            isHistoryLoadAll: false,
            listFace: face,
            baseHost: tools.baseHost,
            showFace: false,
        };
    },
    computed: {
        ...mapState(["userinfo", "currentChat"]),
    },
    watch: {
        currentChat() {
            this.onChatInit(true);
        },
    },
    created() {
        this.onChatInit(true);
        tools.message.on((data) => {
            if (
                this.currentChat != null &&
                data.data.room_uid == this.currentChat.room_uid
            ) {
                const itemRecord = this.settleChatRecord(data.data);
                this.listChatHistory.push(itemRecord);

                this.$nextTick(() => {
                    this.scrollTo(true);
                });
            }
        });
    },
    beforeUnmount() {
        tools.message.clear();
    },
    methods: {
        goInfoCard() {
            var infoType = "user";

            if (this.currentChat.room.type == 1) {
                infoType = "group";
            }
            store.commit("setCurrentNavbar", "team");
            store.commit("setCurrentInfoCardItem", {
                currentItem: this.currentChat.room.target_info,
                type: infoType,
            });
        },
        onFaceSelect(item, index) {
            this.inputContent += index;
        },
        onToggleFace() {
            if (this.showFace) {
                this.showFace = false;
            } else {
                this.showFace = true;
            }
        },
        onChatInit(isClear) {
            if (this.currentChat == null) {
                return false;
            }

            this.isHistoryLoadAll = false;
            this.loadChatHistory(isClear);
            this.clearUncheckMessageCount();
        },
        clearUncheckMessageCount() {
            tools.request(
                "/Chat/clearUncheckMessageCount",
                {
                    room_uid: this.currentChat.room_uid,
                },
                () => {}
            );
            this.currentChat.uncheck_message_count = 0;
            store.commit("setListChatItem", this.currentChat);
        },
        loadChatHistory(isClear) {
            if (this.isHistoryLoadAll) {
                return false;
            }

            var firstChatId = 0;

            if (typeof isClear == "undefined") {
                isClear = false;
            }

            if (isClear) {
                this.listChatHistory = [];
            }

            if (this.listChatHistory.length > 0) {
                firstChatId = this.listChatHistory[0].id;
            }

            tools.request(
                "/Chat/loadChatHistory",
                {
                    room_uid: this.currentChat.room_uid,
                    first_chat_id: firstChatId,
                },
                (result) => {
                    var thisList = result.data;
                    // thisList.reverse();
                    thisList.forEach((itemHistory) => {
                        itemHistory = this.settleChatRecord(itemHistory);
                        this.listChatHistory.unshift(itemHistory);
                    });

                    if (thisList.length == 0) {
                        this.isHistoryLoadAll = true;
                    }

                    this.$nextTick(() => {
                        this.scrollTo(isClear);
                    });
                }
            );
        },
        onChatHistoryScroll(e) {
            if (this.listChatHistory.length == 0) {
                return false;
            }
            if (e.scrollTop == 0) {
                this.loadChatHistory();
            }
        },
        postChat() {
            this.isPosting = true;

            tools.request(
                "/Chat/postChat",
                {
                    room_uid: this.currentChat.room_uid,
                    type: 0,
                    content: this.inputContent,
                },
                () => {
                    this.isPosting = false;
                    this.inputContent = "";

                    this.currentChat.original_update_time = parseInt(
                        Date.now() / 1000
                    );

                    store.commit("setListChatItem", this.currentChat);
                }
            );
        },
        settleChatRecord(itemHistory) {
            var showNickname = itemHistory.nickname;

            var showAvatar = itemHistory.avatar;

            if (itemHistory.user_id == this.userinfo.id) {
                showNickname = this.userinfo.nickname;
                showAvatar = this.userinfo.avatar;
            }

            itemHistory.showNickname = showNickname;
            itemHistory.showAvatar = showAvatar;

            var showContent = itemHistory.content;
            if (itemHistory.type == 0) {
                showContent = itemHistory.content.replace(
                    /(\[[\u2E80-\u9FFF|no|ok]{1,3}\])/gi,
                    function (e, t) {
                        return (
                            "<img src='" +
                            tools.baseHost +
                            face[t] +
                            "'  class='chat-face' />"
                        );
                    }
                );
            } else if (itemHistory.type == 1) {
                // showContent =
                //     '<img class="user-upload" src="' +
                //     itemHistory.content +
                //     '">';
            }
            itemHistory.showContent = showContent;

            return itemHistory;
        },
        scrollTo(isBottom) {
            var height = 0;
            if (typeof isBottom == "undefined") {
                isBottom = true;
            }
            if (isBottom) {
                height = this.$refs.chatHistoryScrollInner.clientHeight;
            }
            this.$refs.chatHistoryScroll.setScrollTop(height);
        },
        beforeUpload(file) {
            this.handleUpload(file);
            return false;
        },
        handleUpload(file) {
            this.uploadTitleImg = true;

            tools.uploadFile(file, "chat", (result) => {
                this.uploadTitleImg = false;
                this.isPosting = true;

                var mimeType = result.data.mime_type;
                var type = 3;
                if (mimeType.split("/")[0] == "image") {
                    type = 1;
                }

                tools.request(
                    "/Chat/postChat",
                    {
                        room_uid: this.currentChat.room_uid,
                        type: type,
                        content: result.data.src,
                        ext_data: result.data,
                    },
                    () => {
                        this.isPosting = false;
                        this.inputContent = "";

                        this.currentChat.original_update_time = parseInt(
                            Date.now() / 1000
                        );

                        store.commit("setListChatItem", this.currentChat);
                    }
                );
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.chat-container {
    height: 100%;
}
.chat-history {
    height: 70%;
    padding: 15px;
}
.chat-input {
    height: 30%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px -1px 1px #bbb;
}
.chat-line-text {
    position: relative;
    font-size: 0;
    margin-bottom: 10px;
    padding-left: 60px;
    min-height: 68px;
}

.chat-option-panel {
    position: absolute;
    bottom: 40px;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0 0 2px #bbb;
}

.chat-option-panel-model {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.chat-face-container {
    width: 400px;
    height: 200px;
    overflow: auto;
    position: relative;
    z-index: 99;
}
.face-item {
    width: 35px;
    padding: 3px;
}
.chat-line-center {
    text-align: center;
}

.chat-chat-mine a {
    color: #fff;
}

.chat-chat-mine .chat-line-text {
    text-align: right;
    padding-left: 0;
    padding-right: 60px;
}

.chat-chat-mine .chat-chat-user {
    left: auto;
    right: 3px;
}

.chat-chat-user {
    position: absolute;
    left: 3px;
}

.chat-chat-main,
.chat-chat-user {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
    font-size: 15px;
}

.chat-chat-user img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.chat-chat-mine .chat-chat-user cite {
    left: auto;
    right: 60px;
    text-align: right;
}

.chat-chat-user cite {
    position: absolute;
    left: 60px;
    top: -2px;

    line-height: 24px;
    font-size: 12px;
    white-space: nowrap;
    color: #999;
    text-align: left;
    font-style: normal;
}

.chat-chat-mine .chat-chat-main {
    margin-left: 0;
    text-align: left;

    color: #fff;
}

.chat-chat-mine .chat-chat-text {
    background-color: #12b7f5;
}

.chat-chat-tips {
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    text-align: center;

    .main {
        margin: 5px auto;
        line-height: 30px;
        padding: 0 15px;
        border-radius: 3px;
        background-color: #ddd;
        display: inline-block;
    }
}

.chat-chat-main {
    position: relative;
    min-height: 22px;
    line-height: 22px;
    margin-top: 25px;
    border-radius: 3px;
    color: #333;
    word-break: break-all;
    max-width: 60%;
}

.chat-chat-text {
    padding: 8px 15px;
    background-color: #fff;
}

.chat-chat-mine .chat-chat-record:after {
    left: auto;
    right: -10px;
}
.chat-chat-mine .chat-chat-text.chat-chat-record:after {
    border-top-color: #12b7f5;
}
.chat-chat-mine .chat-chat-file.chat-chat-record:after {
    border-top-color: #fff;
}

.chat-chat-record:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 13px;
    width: 0;
    height: 0;
    border-style: solid dashed dashed;
    border-color: #fff transparent transparent;
    overflow: hidden;
    border-width: 10px;
}

.chat-input-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
}

.chat-input-option-item {
    margin-left: 10px;
    padding: 0 6px;
    color: rgb(90, 90, 90);
    cursor: pointer;
    font-size: 18px;
    position: relative;
}

.chat-input-textarea {
    height: calc(100% - 70px);
    outline: none;
    overflow: hidden;
}

.chat-input-button {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0 10px;
}

.chat-input-textarea textarea {
    resize: none;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 15px;
}

.system-tips {
    margin: 5px auto;
    margin-bottom: 15px;
    line-height: 30px;
    padding: 0 15px;
    border-radius: 3px;
    background-color: #ddd;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    width: 160px;
}

.system-panel-option {
    font-size: 20px;
    cursor: pointer;
    color: #666;
}
</style>

<style>
.chat-face {
    width: 24px;
}

.user-upload {
    max-width: 200px;
}
</style>
