<template>
    <div class="system-body-main">
        <div class="system-list">
            <div class="system-list-header">
                <a-input-search
                    v-model:value="searchKeywords"
                    placeholder="搜索"
                    size="small"
                    style=""
                    @search="onSearch"
                />
                <plus-square-filled
                    class="option-chat-group"
                    @click="showFriendSelect = true"
                />
            </div>
            <div class="system-list-body">
                <el-scrollbar class="ul-avatar-list" style="">
                    <div
                        class="ul-avatar-list-item"
                        :class="{
                            current:
                                currentChat != null &&
                                itemChat.id == currentChat.id,
                        }"
                        v-for="itemChat in listChatSort"
                        v-bind:key="itemChat.id"
                        @click="onChatClick(itemChat)"
                    >
                        <a-badge :count="itemChat.uncheck_message_count">
                            <a-avatar
                                :size="48"
                                :src="itemChat.room.target_info.title_img"
                            >
                                <template #icon><UserOutlined /></template>
                            </a-avatar>
                        </a-badge>
                        <div class="ul-avatar-list-item-info">
                            <div class="ul-avatar-list-item-title">
                                {{ itemChat.room.target_info.title }}
                                <span v-if="itemChat.room.type == 1">
                                    （{{
                                        itemChat.room.target_info
                                            .chat_group_user_count
                                    }}）
                                </span>
                            </div>
                            <div class="ul-avatar-list-item-tips">
                                {{ itemChat.update_time }}
                            </div>
                            <div class="ul-avatar-list-item-time"></div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="system-panel">
            <ChatComponent />
        </div>
        <FriendSelectComponent
            v-model:show="showFriendSelect"
            @onConfirm="onConfirmSelect"
        ></FriendSelectComponent>
    </div>
</template>

<script>
import ChatComponent from "@/components/ChatComponent";
import FriendSelectComponent from "@/components/FriendSelectComponent";
import tools from "@/assets/js/tools";

import { mapState } from "vuex";
import store from "@/store/main";
export default {
    components: {
        ChatComponent,
        FriendSelectComponent,
    },
    computed: {
        ...mapState(["listChat", "currentChat"]),
        listChatSort: function () {
            return this.listChat.filter(
                (item) =>
                    this.searchKeywords.length == 0 ||
                    item.room.target_info.title.indexOf(this.searchKeywords) >
                        -1
            );
        },
    },

    data() {
        return {
            searchKeywords: "",
            showFriendSelect: false,
        };
    },
    created() {},
    methods: {
        onChatClick(item, index) {
            store.commit("setCurrentChat", item);
        },
        onSearch() {},
        onConfirmSelect(list) {
            var list_user_id = list.map((item) => item.target_user_id);
            if (list_user_id.length == 1) {
                tools.request(
                    "/ChatUser/makeChat",
                    {
                        target_user_id: list_user_id[0],
                    },
                    (result) => {
                        store.dispatch("setCurrentChatByUid", result.data.uid);
                    }
                );
            } else {
                tools.request(
                    "/ChatUser/makeGroup",
                    {
                        list_user_id: list_user_id,
                    },
                    (result) => {
                        store.dispatch("setCurrentChatByUid", result.data.uid);
                        store.dispatch("setListFriend");
                        store.dispatch("setListChat");
                    }
                );
            }
        },
    },
};
</script>

<style scoped>
.option-chat-group {
    font-size: 22px;
    margin-left: 10px;
    cursor: pointer;
    color: #bbb;
}
</style>